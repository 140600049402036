import React, { useEffect, useRef } from 'react'
import Sh from './SH'

export default function SectionTeam () {
  const team = [
    { name: 'Roger Sahyoun', position: 'Chairman', thumb: '/static/rs.webp' },
    { name: 'Dany Azzi', position: 'Regional Executive Creative Director', thumb: '/static/da.webp' },
    { name: 'Joy Sahyoun', position: 'Regional General Manager', thumb: '/static/js.webp' },
    { name: 'Claude Abboud', position: 'General Manager', thumb: '/static/claude-abboud.webp' },
    { name: 'Rita Aoun', position: 'Regional Finance Director', thumb: '/static/ra.webp' },
    { name: 'Walid Nehme', position: 'Planning Director', thumb: '/static/wn.webp' },
    { name: 'Guilda Sahyoun', position: 'Regional HR Manager', thumb: '/static/gs.webp' },

  ]

  const [selected, setSelected] = React.useState(team[0])
  const ref = useRef()
  const videoRef = useRef()

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = '/static/office_1.mp4'
      const playPromise = videoRef.current?.play()
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          videoRef.current.playbackRate = .7
        }).catch(() => {
        })
      }
    }
  }, [])

  return <div ref={ref} className={'overflow-hidden min-h-screen content-around relative py-20 pb-44'}>
    <div className={'container mx-auto relative z-10 mb-20'}>
      <Sh parentRef={ref}>Our team</Sh>
    </div>
    <div className={'w-full container mx-auto relative z-10'}>
      <div
        className={'flex xl:flex-nowrap flex-wrap px-5 items-start justify-center w-full'}>
        {team.map(t =>
          <div
            className={'px-5 w-1/2 mt-5 grayscale lg:w-1/4 md:w-1/3 xl:w-1/6 duration-1000 ' +
              (selected.name === t.name
                  ? 'grayscale-0 px-0 xl:scale-[1.3] xl:mx-8 3xl:mx-10 4xl:mx-12 z-20'
                  : ' '
              )}>
            <div className={'text-white group aspect-[.65] w-full relative duration-1000 cursor-pointer'}
                 onClick={() => setSelected(t)}
                 onMouseEnter={() => setSelected(t)}>
              <div
                className={'overflow-hidden transition-all  bg-white inline-block w-full h-full ' +
                  (selected.name === t.name ? ' shadow-xl ' : ' ')}>
                <img loading={'lazy'} src={t.thumb} className={'h-full object-cover ' +
                  (selected.name === t.name ? ' !scale-100' : ' ')} height={'540px'} width={'350px'}
                     alt={t.thumb}/>
              </div>
              <div
                className={'duration-200 xl:p-0 p-5 xl:bottom-auto bottom-2 left-1 xl:left-auto sm:h-24 ' +
                  'flex content-start xl:flex-none flex-wrap drop-shadow ' +
                  (selected.name === t.name ? 'opacity-100' : 'xl:opacity-5 opacity-60')}>
                <h3 className={'lg:mt-5 text-xl 2xl:-mb-3 w-full'}>
                  {t.name}
                </h3>
                <span className={'-mt-0 2xl:mt-3 2xl:text-base text-sm inline-block md:w-[250px]'}
                      style={{ lineHeight: 1.2 }}>{t.position}</span>
              </div>
            </div>
          </div>)}
      </div>
    </div>
    <video ref={videoRef}
           className={'absolute top-0 z-0 h-full w-screen object-cover opacity-30 grayscale scale-110'}
           autoPlay loop/>
  </div>
}